<template>
  <v-card class="transparent mt-4 pa-2 elevation-0">
    <v-card-title>
      {{ String(value.name).toUpperCase() }}
    </v-card-title>
    <v-card-text>
      <h3 class="my-2">DESCRIPTION :</h3>
      <p class="simple-text" v-html="value.description"></p>

      <!-- Apps -->
      <FormSection
        :label="'Apps assigned'"
        class="mt-0"
        :icon="'mdi-link-variant'"
        :editable="false"
        underline
        right
      ></FormSection>
      <v-slide-group
        v-model="tagIndex"
        class="my-4"
        center-active
        show-arrows
        v-if="value.roleApps && value.roleApps.length"
      >
        <v-slide-item
          v-for="item in value.roleApps"
          :key="item.app.id"
          v-slot="{ toggle }"
        >
          <v-chip class="ma-2" @click="toggle" label text-color="white">
            <v-icon v-if="item.app.icon" left> {{ item.app.icon }} </v-icon>
            {{ item.app.name }}
          </v-chip>
        </v-slide-item>
      </v-slide-group>

      <!-- Views -->
      <FormSection
        :label="'Views assigned'"
        class="mt-0"
        :icon="'mdi-link-variant'"
        :editable="false"
        underline
        right
      ></FormSection>
      <v-slide-group
        v-model="tagIndex"
        class="my-4"
        center-active
        show-arrows
        v-if="value.roleViews && value.roleViews.length"
      >
        <v-slide-item
          v-for="item in value.roleViews"
          :key="item.view.id"
          v-slot="{ toggle }"
        >
          <v-chip class="ma-2" @click="toggle" label text-color="white">
            <v-icon v-if="item.view.icon" left> {{ item.view.icon }} </v-icon>
            {{ item.view.name }}
          </v-chip>
        </v-slide-item>
      </v-slide-group>
      <v-alert v-else class="my-2" dense outlined type="warning">
        No Views assigned, That means that a user with this role can access
        <strong>ANY</strong> view associated with APP
      </v-alert>

      <!-- Views -->
      <FormSection
        :label="'Permissions'"
        class="mt-0"
        :icon="'mdi-link-variant'"
        :editable="false"
        underline
        right
      ></FormSection>
      <v-slide-group
        v-model="tagIndex"
        class="my-4"
        center-active
        show-arrows
        v-if="value.roleViews && value.rolePermissions.length"
      >
        <v-slide-item
          v-for="item in value.roleViews"
          :key="item.view.id"
          v-slot="{ toggle }"
        >
          <v-chip class="ma-2" @click="toggle" label text-color="white">
            <v-icon v-if="item.view.icon" left> {{ item.view.icon }} </v-icon>
            {{ item.view.name }}
          </v-chip>
        </v-slide-item>
      </v-slide-group>
      <v-alert v-else class="my-2" dense outlined type="warning">
        No Permissions assigned, That means that a user with this role can perform
        <strong>ALL</strong> operations on views associated with APP
      </v-alert>
    </v-card-text>
  </v-card>
</template>
    
    
<script>
import FormSection from "../../atoms/common/FormSection.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    FormSection,
  },
  data: () => ({
    tagIndex: null,
    actions: [
      {
        name: "Save",
        description: "Will save changes",
        icon: "mdi-check",
        color: "#23842a",
        on: "onSave",
      },
      {
        name: "Close",
        description: "Will clear the form",
        icon: "mdi-close",
        color: "#0e1721",
        on: "onClear",
      },
      {
        name: "Settings",
        description: "Change General configurations",
        icon: "mdi-cog",
        color: "#23842a",
        on: "onSettings",
      },
    ],
  }),
  computed: {},
  async created() {},
  methods: {},
  watch: {},
};
</script>