<template>
  <AView
    :value="value"
    :actions="actions"
    :active="role.id === activeRoleId"
    :name="role.name"
    ref="role-editor-view"
    @onHover="onHover"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onSettings="onSettings"
  >
    <template v-slot:content="{}">
      <RoleDetailsVue :value="role"></RoleDetailsVue>
    </template>
  </AView>
</template>
  
  
  <script>
import { mapGetters, mapState } from "vuex";
import RoleDetailsVue from "../../../../../../components/wad/organisms/roles/RoleDetails.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    RoleDetailsVue,
  },
  data: () => ({
    tagIndex: null,
    role: {},
    actions: [
      {
        name: "Save",
        description: "Will save changes",
        icon: "mdi-check",
        color: "#23842a",
        on: "onSave",
      },
      {
        name: "Close",
        description: "Will clear the form",
        icon: "mdi-close",
        color: "#0e1721",
        on: "onClear",
      },
      {
        name: "Settings",
        description: "Change General configurations",
        icon: "mdi-cog",
        color: "#23842a",
        on: "onSettings",
      },
    ],
  }),
  computed: {
    ...mapState("RoleStore", ["activeRoleId", "displayedRoles"]),
    ...mapGetters("RoleStore", ["roleById"]),
  },
  async created() {
    await this.$store.dispatch("RoleStore/GetRole", {
      id: this.value.relation.params.roleId,
    });

    this.role = this.roleById(this.value.relation.params.roleId);
  },
  methods: {
    onHover() {
      this.$store.commit(
        "RoleStore/setActiveId",
        this.value.relation.params.roleId
      );
    },
    onRoleClick(role) {
      this.$store.commit("RoleStore/setActiveId", role.id);
    },
    onSettings() {
      this.$store.dispatch("RoleStore/openRoleGeneralEditor", {
        from: this.value,
        role: this.role,
      });
    },
    onRoleRemove() {},
  },
  watch: {
    displayedRoles: {
      async handler(newVal) {
        if (newVal && !newVal.IN_PROGRESS) {
          //   //   this.role = Object.assign(
          //   //     this.role,
          //   this.role = this.roleById(this.value.relation.params.roleId);
          //   //   );

          this.role = Object.assign(
            this.role,
            this.roleById(this.value.relation.params.roleId)
          );
        }
      },
      deep: true,
    },
  },
};
</script>